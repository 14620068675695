<template>
  <div>
    <header :class="$style.header">
      <ClientOnly>
        <div :class="$style.headerInner">
          <h1 v-if="isPc">
            <nuxt-link to="/">
              <img
                src="/img/top/logo.svg"
                width="207"
                height="19"
                alt="WARC エージェント"
              />
            </nuxt-link>
          </h1>
          <div :class="$style.headerButton">
            <ButtonsMagazineArticleButton
              text="無料カウンセリング"
              type="link"
              :path="fullUrl('/registration')"
              imgPath="articles/icon_circle_white.svg"
            />
          </div>
          <div :class="$style.headerButton">
            <ButtonsMagazineArticleButton
              :text="isPc ? '法人の方はこちら' : '法人の方'"
              type="link"
              path="https://warc-synca-skill.typeform.com/to/SnKhhLsl"
              colorType="secondary"
              target="_blank"
            />
          </div>
        </div>
      </ClientOnly>
    </header>
    <slot />
    <footer :class="$style.footer">
      <div :class="$style.footerWrapper">
        <ul :class="$style.links">
          <li
            v-for="(l, key) in LEFT_LINKS"
            :key="key"
            :class="$style.linksList"
          >
            <nuxt-link
              :to="l.isBlank ? l.path : fullUrl(l.path)"
              :class="$style.linkItem"
              :target="l.isBlank ? '_blank' : '_self'"
            >
              <img
                src="/img/articles/categories/icon-arrow-right.svg"
                width="16"
                height="16"
                alt=""
              />
              <span :class="$style.linkItemLabel">{{ l.label }}</span>
            </nuxt-link>
          </li>
        </ul>
        <ul :class="$style.links">
          <li
            v-for="(l, key) in CENTER_LINKS"
            :key="key"
            :class="$style.linksList"
          >
            <nuxt-link
              :to="l.isBlank ? l.path : fullUrl(l.path)"
              :class="$style.linkItem"
              :target="l.isBlank ? '_blank' : '_self'"
            >
              <img
                src="/img/articles/categories/icon-arrow-right.svg"
                width="16"
                height="16"
                alt=""
              />
              <span :class="$style.linkItemLabel">{{ l.label }}</span>
            </nuxt-link>
          </li>
        </ul>
        <ul :class="$style.links">
          <li
            v-for="(l, key) in RIGHT_LINKS"
            :key="key"
            :class="$style.linksList"
          >
            <nuxt-link
              :target="l.isBlank ? '_blank' : '_self'"
              :to="l.isBlank ? l.path : fullUrl(l.path)"
              :class="$style.linkItem"
            >
              <img
                src="/img/articles/categories/icon-arrow-right.svg"
                width="16"
                height="16"
                alt=""
              />
              <span :class="$style.linkItemLabel">{{ l.label }}</span>
            </nuxt-link>
          </li>
        </ul>
        <div :class="$style.footerContainer">
          <div>
            <nuxt-link to="/">
              <img
                src="/img/top/logo.svg"
                width="207"
                height="19"
                alt="WARC エージェント"
              />
            </nuxt-link>
          </div>
        </div>
        <span :class="$style.footerCopy">&copy; WARC.inc</span>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
const LEFT_LINKS = [
  {
    label: '経理',
    path: '/articles/categories/accounting',
    isBlank: false,
  },
  {
    label: '労務',
    path: '/articles/categories/labor',
    isBlank: false,
  },
  {
    label: 'バックオフィス',
    path: '/articles/categories/backoffice',
    isBlank: false,
  },
  {
    label: '管理',
    path: '/articles/categories/management',
    isBlank: false,
  },
  {
    label: '財務',
    path: '/articles/categories/finance',
    isBlank: false,
  },
  {
    label: '人事',
    path: '/articles/categories/human_resources',
    isBlank: false,
  },
  {
    label: '法務',
    path: '/articles/categories/legal',
    isBlank: false,
  },
  {
    label: '会計',
    path: '/articles/categories/bookkeeping',
    isBlank: false,
  },
  {
    label: 'スタートアップ',
    path: '/articles/categories/startup',
    isBlank: false,
  },
  {
    label: '監査法人',
    path: '/articles/categories/auditing_firm',
    isBlank: false,
  },
  {
    label: '経営企画',
    path: '/articles/categories/business_planning',
    isBlank: false,
  },
] as const;

const CENTER_LINKS = [
  {
    label: 'IR',
    path: '/articles/categories/investor_relations',
    isBlank: false,
  },
  {
    label: '広報',
    path: '/articles/categories/public_relations',
    isBlank: false,
  },
  {
    label: 'CFO',
    path: '/articles/categories/chief_financial_officer',
    isBlank: false,
  },
  {
    label: '情シス',
    path: '/articles/categories/information_system',
    isBlank: false,
  },
  {
    label: '資格',
    path: '/articles/categories/certification',
    isBlank: false,
  },
  {
    label: '知識',
    path: '/articles/categories/knowledges',
    isBlank: false,
  },
  {
    label: '制度',
    path: '/articles/categories/system',
    isBlank: false,
  },
  {
    label: 'BtoB',
    path: '/articles/categories/b2b',
    isBlank: false,
  },
  {
    label: 'その他',
    path: '/articles/categories/others',
    isBlank: false,
  },
  {
    label: '導入事例',
    path: '/articles/categories/case_studies',
    isBlank: false,
  },
  {
    label: 'ニュース',
    path: '/articles/categories/news',
    isBlank: false,
  },
] as const;

const RIGHT_LINKS = [
  {
    label: 'WARCエージェント マガジン',
    path: '/articles',
    isBlank: false,
  },
  {
    label: 'プライバシーポリシー',
    path: 'https://corp.warc.jp/privacy-policy/',
    isBlank: true,
  },
  {
    label: '会社概要',
    path: 'https://corp.warc.jp/',
    isBlank: true,
  },
  {
    label: 'WARCエージェント',
    path: '/',
    isBlank: false,
  },
] as const;

const isPc = useState('isPc', () => false);
const checkWindowWidth = () => {
  isPc.value = window.innerWidth > 767;
};

onMounted(() => {
  checkWindowWidth();
  window.addEventListener('resize', checkWindowWidth);
});
</script>

<style lang="scss" module>
.header {
  height: 8rem;
  background-color: #f7f7f7;

  @include sp {
    height: 4.8rem;
  }
}

.headerInner {
  max-width: 128rem;
  margin: 0 auto;
  height: 100%;
  padding: 0 mp(32);
  display: flex;
  align-items: center;
  h1:first-of-type {
    margin-right: auto;
  }

  @include sp {
    padding: 0;
  }
}

.headerButton {
  margin-left: auto;

  &:nth-of-type(1) {
    width: 18.6rem;

    @include sp {
      width: 18rem;
    }
  }

  &:nth-of-type(2) {
    margin-left: mp(20);
    width: 18.6rem;

    @include sp {
      margin-left: 0;
      width: 7.3rem;
    }
  }
}

.footer {
  background-color: colors(btn-quarterly-hover);
  padding: mp(48) mp(64) mp(24) mp(64);

  @include sp {
    padding: mp(32) mp(20) mp(20) mp(20);
  }
}

.footerWrapper {
  max-width: 128rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: mp(40);

  @include sp {
    width: 100%;
    margin: 0;
    gap: 0;
  }
}

.links {
  display: flex;
  flex-direction: column;
  gap: mp(4);

  &:nth-of-type(1) {
    width: 16.75rem;

    @include sp {
      width: 50%;
    }
  }

  &:nth-of-type(2) {
    width: 16.75rem;

    @include sp {
      width: 50%;
    }
  }

  &:nth-of-type(3) {
    width: 23.1rem;

    @include sp {
      width: 100%;
      margin-top: mp(32);
    }
  }

  @include sp {
    gap: mp(8);
  }
}

.linksList {
  display: flex;
}

.linkItem {
  display: flex;
  align-items: center;
  padding: mp(4) mp(4);
}

.linkItemLabel {
  color: colors(base-able);
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.98px;

  @include hover {
    color: colors(base-hover);
  }
}

.footerContainer {
  margin-right: auto;

  @include sp {
    margin-top: mp(48);
  }
}

.footerCopy {
  width: 100%;
  display: block;
  text-align: center;
  color: colors(base-able);
  margin-top: mp(40);
  @include text(13_lg);

  @include sp {
    margin-top: mp(24);
  }
}
</style>
